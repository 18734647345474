import React, { FC } from 'react'
import { format } from 'date-fns-tz'
import { TModalFilters } from '../../interfaces/IFilters'
import { REPORT_DATETIME_FORMAT } from '../../enums/common'
import {
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  FILTER_APPOINTMENTS_FIELD,
  FILTER_CIBLE_1_FIELD,
  FILTER_CIBLE_2_FIELD,
  FILTER_DECILE_BEBE,
  FILTER_GROUPMENT_FIELD,
  FILTER_LAST_VISIT_START_FIELD,
  FILTER_RANGE_FIELD,
} from '../../constants/filters'
import { FILTERS_RADIO_OPTIONS_LIST, RANGE_LABEL } from '../../constants/form'
import { useFiltersHook } from '../../hooks/accountsPortfolio/UseFiltersHook'

import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { fieldHasValue } from '../../utils/filters'
import { ISelectOption } from '../../interfaces/IForms'
import {
  FILTER_CLIENT_CURRENT_YEAR,
  FILTER_CLIENT_PREVIOUS_YEAR,
} from '../../constants/dashboard'
import { currentYearValue, getCurrentYear } from '../../utils/helpers'

export const ActiveFiltersSection: FC<
  TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
> = ({
  setFilters,
  changePage,
  filters,
  fieldsData,
  resetRoute,
  containerClassName,
  callback,
}) => {
  const { resetFilters, resetFiltersOnSimpleSearch } = useFiltersHook(
    {
      setFilters,
      changePage,
      fieldsData,
    },
    callback
  )

  /**
   * Retrieve display filter value based on filter type
   *
   * @param filter
   * @param selectedValue
   */
  const filterValueDisplay = (
    filter: string,
    selectedValue: string | Array<ISelectOption>
  ) => {
    switch (filter) {
      case FILTER_CIBLE_1_FIELD:
      case FILTER_CIBLE_2_FIELD:
      case FILTER_APPOINTMENTS_FIELD:
      case FILTER_GROUPMENT_FIELD:
        return Array.isArray(selectedValue)
          ? selectedValue.map((item) => item.label).join(', ')
          : selectedValue
      case FILTER_DECILE_BEBE: {
        return Array.isArray(selectedValue)
          ? selectedValue.map((item) => item.label).join(', ')
          : selectedValue
      }
      case FILTER_RANGE_FIELD:
        return `${selectedValue} ${RANGE_LABEL}`
      case FILTER_LAST_VISIT_START_FIELD: {
        return format(
          new Date(selectedValue.toString()),
          REPORT_DATETIME_FORMAT
        )
      }
      case FILTER_CLIENT_CURRENT_YEAR:
      case FILTER_CLIENT_PREVIOUS_YEAR:
        return FILTERS_RADIO_OPTIONS_LIST.filter(
          (option) => option.value === selectedValue[0]
        )[0].label
      default:
        return selectedValue
    }
  }

  return (
    <div
      className={`pageFiltersActiveSearch col12 colXl10 colMd12 mt05 ${containerClassName}`}
    >
      <Button
        className="btn btnLink clearFilters pl0"
        data-qa="ctaClearFilters"
        onClick={() =>
          filters?.search
            ? resetFiltersOnSimpleSearch(resetRoute)
            : resetFilters(resetRoute)
        }
      >
        <DynamicFormattedMessage id="form.button.resetFilters" />
      </Button>
      <div className="filters">
        {filters &&
          Object.entries(filters).map(([filter, searchValue]) => {
            const fieldNotEmpty = fieldHasValue(searchValue)
            const display =
              fieldNotEmpty && filterValueDisplay(filter, searchValue)

            const filterWithYear = filter === FILTER_CIBLE_2_FIELD
            const yearValue = filterWithYear
              ? getCurrentYear()
              : currentYearValue

            const yearDisplayValue =
              filterWithYear || filter === FILTER_CLIENT_CURRENT_YEAR
                ? yearValue
                : yearValue - 1

            return (
              fieldNotEmpty && (
                <span className="filter" key={`${filter}_key`}>
                  <DynamicFormattedMessage
                    id={`form.field.${filter}.label`}
                    values={{
                      year: yearDisplayValue,
                    }}
                  />
                  {display && (
                    <>
                      : <span className={`filter-${filter}`}>{display}</span>
                    </>
                  )}
                  ;
                </span>
              )
            )
          })}
      </div>
    </div>
  )
}
